<template>
  <div>
    <h3 class="mb-5 text-secondary">{{ $t("page.issue.title") }}</h3>
    <filters class="mb-5" @createIssue="openCreateIssueModal" />
    <issue-table
      :issues="formatedIssues"
      :fields="fields"
      :actions="actions"
      :sortBy.sync="sortBy"
      :sortDesc.sync="sortDesc"
    />
    <create-issue
      :state="createModalState"
      :callbackAfterCreate="retrieveIssues"
      @close="closeCreateIssueModal"
    />
    <edit-issue
      :state="editModalState"
      :issue="selectedIssue"
      :callbackAfterEdit="retrieveIssues"
      @close="closeEditIssueModal"
    />
  </div>
</template>

<script>
import { EventBus } from "./../eventBus.js";

import Filters from "./../components/Issue/Filters";
import IssueTable from "./../components/Issue/IssueTable";
import CreateIssue from "./../components/Issue/CreateIssue";
import EditIssue from "./../components/Issue/EditIssue";
export default {
  components: {
    Filters,
    IssueTable,
    CreateIssue,
    EditIssue
  },
  data() {
    return {
      createModalState: false,
      editModalState: false,
      fields: [
        { key: "condominiumName", label: "condominium", sort: true },
        { key: "createdAt", label: "date", sort: true },
        { key: "title", label: "issue", sort: true },
        { key: "status", label: "status", sort: false },
        { key: "totalComments", label: "commentsNumber", sort: true },
        { key: "maintainer", label: "maintainer", sort: true },
        { key: "reported", label: "reported", sort: false },
        { key: "actions", label: "" }
      ],
      actions: [
        {
          label: "button.manage",
          callbackFunc: this.openEditIssueModal
        }
      ]
    };
  },
  mounted() {
    EventBus.$on("eventBusReloadIssues", this.reloadData);
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$route.params.id) {
          this.openEditIssueModal(this.$route.params.id);
        }
      }
    }
  },
  computed: {
    issues() {
      return this.$store.getters["issue/getIssues"];
    },
    selectedIssue() {
      return this.$store.getters["issue/getCurrentIssue"];
    },
    formatedIssues() {
      return this.issues.map(item => {
        return {
          id: item._id,
          condominiumName: item.condominiumId.name,
          createdAt: item.createdAt,
          title: item.title,
          photos: item.photos,
          totalPhotos: item.photos.length,
          status: item.status,
          type: item.type,
          totalComments: item.comments.filter(comment => comment.ownerId)
            .length,
          maintainer: item.maintainerId,
          reported: item.reported || item.comments.find(c => c.reported)
        };
      });
    },
    sortBy: {
      get() {
        return this.$store.getters["issue/getSortBy"];
      },
      set(value) {
        this.$store.commit("issue/SET_SORT_BY", value);
      }
    },
    sortDesc: {
      get() {
        return this.$store.getters["issue/getSortDesc"];
      },
      set(value) {
        this.$store.commit("issue/SET_SORT_DESC", value);
      }
    }
  },
  methods: {
    async reloadData() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.retrieveIssues();
        await this.$store.dispatch("maintainer/retrieveAdminMaintainers");
        await this.$store.dispatch("condominium/retrieveCondominiums");
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    async retrieveIssues() {
      await this.$store.dispatch("issue/retrieveUserIssues");
    },
    openCreateIssueModal() {
      this.createModalState = true;
    },
    openEditIssueModal(id) {
      const selectedIssue = this.issues.find(issue => issue._id === id);
      this.$store.commit("issue/SET_CURRENT_ISSUE", selectedIssue);
      this.editModalState = true;
    },
    closeCreateIssueModal() {
      this.createModalState = false;
    },
    closeEditIssueModal() {
      this.editModalState = false;
    }
  },
  beforeDestroy() {
    EventBus.$off("eventBusReloadIssues");
  }
};
</script>

<style lang="scss" scoped></style>
