<template>
  <b-row class="no-gutters justify-content-between">
    <b-col class="col-6">
      <b-form-group
        :label="$t('page.issue.typeLabel')"
        label-class="font-weight-bold"
      >
        <b-form-checkbox-group v-model="activeTypes" id="issue-type">
          <b-row class="no-gutters justify-content-between">
            <b-col
              class="col-12 col-sm-6 col-xl-4"
              v-for="(type, index) in typeOptions"
              :key="index"
            >
              <b-form-checkbox v-if="$is1Board" :value="type._id">
                {{ type.description }}
              </b-form-checkbox>
              <b-form-checkbox v-else :value="type">
                <b-icon style="width: 12px" :icon="icons[type]" />
                {{ $t(`fixCategories.${type}`) }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-form-checkbox-group>
      </b-form-group>
    </b-col>
    <b-col class="col-3">
      <b-form-group
        :label="$t('page.issue.statusLabel')"
        label-class="font-weight-bold"
      >
        <b-form-checkbox-group v-model="activeStatus" id="issue-status" stacked>
          <b-form-checkbox
            v-for="(status, index) in statusOptions"
            :key="index"
            :value="status"
          >
            <b-badge
              class="text-white font-weight-normal"
              :variant="statusVariant[status]"
            >
              {{ $t(`page.issue.status.${status}`) }}
            </b-badge>
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group>
    </b-col>
    <b-col class="col-3">
      <b-form-group
        :label="$t('page.issue.condominiumLabel')"
        label-class="font-weight-bold"
      >
        <b-form-select v-model="activeCondominium" id="issue-condominium">
          <b-form-select-option :value="null">
            {{ $t("all") }}
          </b-form-select-option>
          <b-form-select-option
            v-for="(condominium, index) in condominiumOptions"
            :key="index"
            :value="condominium._id"
          >
            {{ condominium.name }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-checkbox
        id="report_status"
        v-model="reportedFilter"
        name="report_status"
      >
        {{ $t("button.showReported") }}
      </b-form-checkbox>
      <b-row class="no-gutters justify-content-end">
        <b-button
          v-if="!$_authUserIsDomi"
          class="mt-3"
          size="md"
          variant="secondary"
          @click="createIssue"
        >
          {{ $t("button.createIssue") }}
        </b-button>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  ISSUE_TYPE_ICONS,
  ISSUE_STATUS_VARIANTS,
  SORT_FILTERS
} from "./../../Utils";
import { authMixin, appMixin } from "./../../mixins";

export default {
  mixins: [authMixin, appMixin],
  data() {
    return {
      statusVariant: ISSUE_STATUS_VARIANTS,
      icons: ISSUE_TYPE_ICONS
    };
  },
  computed: {
    activeTypes: {
      get() {
        return this.$store.getters["issue/getFilterTypes"];
      },
      set(value) {
        this.$store.commit("issue/SET_FILTER_TYPES", value);
        this.retrieveIssues();
      }
    },
    activeStatus: {
      get() {
        return this.$store.getters["issue/getFilterStatus"];
      },
      set(value) {
        this.$store.commit("issue/SET_FILTER_STATUS", value);
        this.retrieveIssues();
      }
    },
    activeCondominium: {
      get() {
        return this.$store.getters["issue/getFilterCondominium"];
      },
      set(value) {
        this.$store.commit("issue/SET_FILTER_CONDOMINIUM", value);
        this.retrieveIssues();
      }
    },
    reportedFilter: {
      get() {
        return this.$store.getters["issue/getFilterReported"];
      },
      set(value) {
        this.$store.commit("issue/SET_FILTER_REPORTED", value);
        this.retrieveIssues();
      }
    },
    typeOptions() {
      if (this.$is1Board)
        return this.$store.getters["parameters/getActiveCategories"];
      return this.$store.getters["issue/getTypeOptions"];
    },
    statusOptions() {
      return this.$store.getters["issue/getStatusOptions"];
    },
    condominiumOptions() {
      return SORT_FILTERS(
        this.$store.getters["condominium/getActiveCondominiums"],
        "name"
      );
    }
  },
  methods: {
    async retrieveIssues() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("issue/retrieveUserIssues");
      } catch {
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    createIssue() {
      this.$emit("createIssue");
    }
  }
};
</script>

<style lang="scss" scoped></style>
